// Page 404
// Render 404 view
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Card, CardTitle, CardText, CardBody, Row, Col, Button} from 'reactstrap'
import Jumbotron from '../components/Jumbotron'
import FlatEarthImg from '../images/flatearth.png'

const NotFoundPage = () => (
  <Layout pageTitle="Arsip File">
    <SEO 
      title="Arsip file" 
      lang="id"
      img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2Fkisspng-documentation-organization-logo-service-forms-5b112049d36aa6.562040021527849033866.png?alt=media&token=5c95988e-6aa7-414e-bf99-e8bde2d0a340"
      description="Arsip File Waingapu Open Mind"      
    />
    <Jumbotron title="Arsip File Waingapu Open Mind" info="File-file yang bermanfaat dan dibagikan secara gratis oleh anak-anak di Waingapu Open Mind yang berbaik hati." />
	<Container>
		<Row>
			{/*
			<Col md="4">
				<Card className="text-center">
			        <img alt="arsip-file" className="card-image-top mt-3" src={FlatEarthImg} style={{ width:'50%', heigth:'50%', margin:'auto'}} />
			 		<CardBody>
		 				<CardTitle className="text-uppercase mb-3">Flat Earth</CardTitle>
		 				<CardText>Membahas mengenai teori bumi datar</CardText>
		 				<div className="text-right"> 
		 					<Button classname="text-uppercase" style={{color: '#FFFFFF', backgroundColor: '#343A40'}} href={``} >
		 						Buka Arsip File
		 					</Button> </div>
			 		</CardBody>

				</Card>
			</Col>

			<Col md="4">
				<Card className="text-center">
			        <img alt="arsip-file" className="card-image-top mt-3" src={FlatEarthImg} style={{ width:'50%', heigth:'50%', margin:'auto'}} />
			 		<CardBody>
		 				<CardTitle className="text-uppercase mb-3">Covid 19</CardTitle>
		 				<CardText>Membahas mengenai Virus Corona (Covid 19)</CardText>
		 				<div className="text-right"> 
		 					<Button classname="text-uppercase" style={{color: '#FFFFFF', backgroundColor: '#343A40'}} href={``} >
		 						Buka Arsip File
		 					</Button> </div>
			 		</CardBody>

				</Card>
			</Col>

			<Col md="4">
				<Card className="text-center">
			        <img alt="arsip-file" className="card-image-top mt-3" src={FlatEarthImg} style={{ width:'50%', heigth:'50%', margin:'auto'}} />
			 		<CardBody>
		 				<CardTitle className="text-uppercase mb-3">Elite Global</CardTitle>
		 				<CardText>Membahas mengenai Elite Global</CardText>
		 				<div className="text-right"> 
		 					<Button classname="text-uppercase" style={{color: '#FFFFFF', backgroundColor: '#343A40'}} href={``} >
		 						Buka Arsip File
		 					</Button> </div>
			 		</CardBody>

				</Card>
			</Col>
			*/}
	
		</Row>
    </Container>

  </Layout>
)

export default NotFoundPage


    // <Card>
    //   <Link to={slug}>
    //     <Img className="Card-image-top" fluid={fluid} />
    //   </Link>
    //   <CardBody>
    //     <CardTitle>
    //       <h2><Link to={slug} style={{color: '#424242'}}>{title}</Link></h2>
    //     </CardTitle>
    //     <CardSubtitle style={{color: '#000000'}}>
    //       <strong>{date}</strong> by{' '}
    //       <strong>{author}</strong>         
    //     </CardSubtitle>
    //     <CardText>{body}</CardText>
    //     <ul className="post-tags">
    //       {tags.map(tag => (
    //         <li key={tag}>
    //           <Link to={`/tag/${slugify(tag)}`}>
    //             <Badge color="dark" className="text-uppercase">{tag}</Badge>
    //           </Link>
    //         </li>
    //       ))}
    //     </ul> 

    //     <Link to={slug} className="btn btn-outline-dark float-right">Baca Lebih Banyak</Link>
    //   </CardBody>
    // </Card>
